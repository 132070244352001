import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['collectionContainer'];

    static values = {
        index: Number,
        prototype: String,
        prototypeName: { type: String, default: 'name' },
    };

    addCollectionElement(event) {
        const item = document.createElement('li');
        const regexPrototypeNameValue = new RegExp(
            '__' + this.prototypeNameValue + '__',
            'g',
        );
        item.innerHTML = this.prototypeValue.replace(
            regexPrototypeNameValue,
            this.indexValue,
        );
        this.collectionContainerTarget.appendChild(item);
        this.indexValue++;
    }

    removeCollectionElement(event) {
        event.currentTarget.closest('li').remove();
        this.indexValue--;
    }
}
