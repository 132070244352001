import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    body;
    static targets = ['filters'];

    constructor(context) {
        super(context);
        this.body = document.querySelector('body');
    }

    initialize() {
        super.initialize();
    }

    open() {
        this.filtersTarget.classList.remove('filters--hidden');
        this.body.style.overflow = 'hidden';
    }

    close() {
        this.filtersTarget.classList.add('filters--hidden');
        this.body.style.overflow = 'auto';
    }
}
