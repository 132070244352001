import { Controller } from '@hotwired/stimulus';
import Splide from '@splidejs/splide';

export default class extends Controller {
    splide;
    connect() {
        this.splide = new Splide('#mosaic-mobile', {
            perPage: 1,
            perMove: 1,
            rewind: true,
            rewindByDrag: true,
            pagination: true,
            arrows: false,
            fixedHeight: '50vh',
            classes: {
                page: 'splide__pagination__page bg-content-quiet',
            },
        }).mount();
    }

    next() {
        this.splide.go('>');
    }

    prev() {
        this.splide.go('<');
    }
}
