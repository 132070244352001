import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['popover', 'overlay', 'container'];
    static values = {
        trigger: {
            type: String,
            default: 'click',
        },
    };

    connect() {
        if (this.triggerValue === 'hover' && this.containerTarget) {
            this.containerTarget.addEventListener('mouseenter', this.open);
            this.containerTarget.addEventListener('mouseleave', this.close);
        }
    }

    disconnect() {
        if (this.triggerValue === 'hover' && this.containerTarget) {
            this.containerTarget.removeEventListener('mouseenter', this.open);
            this.containerTarget.removeEventListener('mouseleave', this.close);
        }
    }

    open = () => {
        this.popoverTarget.classList.add('popover--show');
        if (this.hasOverlayTarget)
            this.overlayTarget.classList.add('popover__overlay--show');
        window.addEventListener('click', this.close);
    };

    toggle = () => {
        if (this.popoverTarget.classList.contains('popover--show')) {
            this.popoverTarget.classList.remove('popover--show');
            if (this.hasOverlayTarget)
                this.overlayTarget.classList.remove('popover__overlay--show');
            window.addEventListener('click', this.close);
            return;
        }

        this.popoverTarget.classList.add('popover--show');
        this.overlayTarget.classList.add('popover__overlay--show');
    };

    close = () => {
        this.popoverTarget.classList.remove('popover--show');
        if (this.hasOverlayTarget)
            this.overlayTarget.classList.remove('popover__overlay--show');
        window.removeEventListener('click', this.close);
    };

    onClickOutside = (event) => {
        if (event.target === this.overlayTarget) {
            this.close();
        }
    };
}
