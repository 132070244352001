import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['submitBtn', 'form'];

    connect() {
        this.isFormDirty = false;
        this.isSubmitting = false;
        this.initChangeDetection();

        // Add event listeners to form fields to detect changes
        this.formTarget.addEventListener(
            'input',
            this.markFormAsDirty.bind(this),
        );
        window.addEventListener(
            'beforeunload',
            this.handleBeforeUnload.bind(this),
        );
        this.formTarget.addEventListener(
            'submit',
            this.handleFormSubmit.bind(this),
        );
    }

    disconnect() {
        // Clean up event listeners when the controller is disconnected
        window.removeEventListener(
            'beforeunload',
            this.handleBeforeUnload.bind(this),
        );
    }

    markFormAsDirty() {
        this.isFormDirty = true;
        if (this.formHasChanges()) {
            this.submitBtnTarget.disabled = false;
            this.submitBtnTarget.classList.remove('opacity-[0.38]');
        } else {
            this.submitBtnTarget.disabled = true;
            this.submitBtnTarget.classList.add('opacity-[0.38]');
        }
    }

    handleBeforeUnload(event) {
        if (this.isFormDirty && !this.isSubmitting) {
            const confirmationMessage =
                'You have unsaved changes. Are you sure you want to leave?';
            event.returnValue = confirmationMessage;
            return confirmationMessage;
        }
    }

    handleFormSubmit() {
        // Set the submitting flag to true
        this.isSubmitting = true;

        // Temporarily remove the beforeunload event listener
        window.removeEventListener(
            'beforeunload',
            this.handleBeforeUnload.bind(this),
        );

        // Reset the isFormDirty flag after form submission
        this.isFormDirty = false;
    }

    initChangeDetection() {
        Array.from(this.formTarget).forEach(
            (el) => (el.dataset.origValue = el.value),
        );
    }

    formHasChanges() {
        this.isFormDirty = Array.from(this.formTarget).some(
            (el) =>
                'origValue' in el.dataset && el.dataset.origValue !== el.value,
        );
        return this.isFormDirty;
    }
}
