import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['text', 'button', 'availabilities'];
    static values = {
        visible: Boolean,
    };
    toggle(event) {
        const style = getComputedStyle(this.textTarget)?.whiteSpace;

        if (style === 'nowrap') {
            this.textTarget.style.whiteSpace = 'break-spaces';
            this.buttonTarget.innerHTML =
                'Masquer <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">\n' +
                '  <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />\n' +
                '</svg>';

            return;
        }

        this.textTarget.style.whiteSpace = 'nowrap';
        this.buttonTarget.innerHTML =
            'En savoir plus <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">\n' +
            '  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />\n' +
            '</svg>';
    }

    toggleDescription(event) {
        const targetClass = this.textTarget.classList;
        targetClass.toggle('ski-view__block-hidden');

        if (targetClass.length === 0) {
            this.buttonTarget.innerHTML =
                'Masquer <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">\n' +
                '  <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />\n' +
                '</svg>';

            return;
        }

        this.buttonTarget.innerHTML =
            'En savoir plus <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">\n' +
            '  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />\n' +
            '</svg>';
    }

    toggleAvailabilities(event) {
        const targets = this.availabilitiesTargets;

        for (let i = 0; i < targets.length; i++) {
            let availability = targets[i].classList;
            if (!this.visibleValue) {
                availability.remove('hidden');
                continue;
            }

            availability.add('hidden');
        }

        this.visibleValue = !this.visibleValue;

        if (this.visibleValue) {
            this.buttonTarget.innerHTML =
                'Masquer <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">\n' +
                '  <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />\n' +
                '</svg>';

            return;
        }

        this.buttonTarget.innerHTML =
            'Voir plus de disponibilités\n' +
            ' <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">\n' +
            ' <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />\n' +
            ' </svg>';
    }
}
