import { Controller } from '@hotwired/stimulus';
import Splide from '@splidejs/splide';

export default class extends Controller {
    static values = {
        carouselId: String, // mandatory, allow multiple instances of carousel in one page
        config: Object,
    };
    static splide;

    initialConfig = {
        focus: 'center',
        breakpoints: {
            768: {
                start: 0,
                perMove: 1,
                padding: '20px',
            },
        },
        autoWidth: true,
        autoHeight: true,
        gap: '16px',
        padding: '64px',
        arrows: false,
        pagination: false,
        flickPower: 100,
        rewind: true,
        rewindSpeed: 1000,
        rewindByDrag: true,
    };

    connect() {
        this.splide = new Splide(`#${this.carouselIdValue}`, {
            ...this.initialConfig,
            ...this.configValue,
        }).mount();
    }

    next = () => {
        this.splide.go('>');
    };

    prev = () => {
        this.splide.go('<');
    };
}
