import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['select', 'options'];
    selectId = null;
    selectInput = null;

    initialize() {
        this.selectId = this.selectTarget.getAttribute(
            'data-select-multiple-partner-name',
        );
        this.selectInput = this.selectId
            ? document.getElementById(this.selectId)
            : null;

        for (let o of this.selectInput.selectedOptions) {
            let selectedInput = document.querySelector(
                `input[value="${o.value}"]`,
            );
            selectedInput.checked = true;
        }
    }

    toggle(event) {
        const selectMultiples = document.querySelectorAll('.select-multiple');
        selectMultiples.forEach((selectMultiple) => {
            if (selectMultiple !== this.selectTarget) {
                selectMultiple.classList.remove('select-multiple__display');
            }
        });

        this.selectTarget.classList.toggle('select-multiple__display');
        window.addEventListener('click', this.hide);
    }

    hide = (event) => {
        if (event && this.selectTarget.contains(event.target)) {
            return;
        }

        this.selectTarget.classList.remove('select-multiple__display');
        window.removeEventListener('click', this.hide);
    };

    add(event) {
        if (null === this.selectInput) {
            return;
        }

        for (let i = 0; i < this.selectInput.options.length; i++) {
            if (event.target.value === this.selectInput.options[i].value) {
                this.selectInput.options[i].selected = !!event.target.checked;
            }
        }
    }

    selectAll() {
        this.optionsTarget.querySelectorAll('li').forEach((item, index) => {
            item.querySelector('input').checked = true;
            this.selectInput.options[index].selected = true;
        });
    }

    unselectAll() {
        this.optionsTarget.querySelectorAll('li').forEach((item, index) => {
            item.querySelector('input').checked = false;
            this.selectInput.options[index].selected = false;
        });
    }
}
