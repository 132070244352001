import { Controller } from '@hotwired/stimulus';
import { toggleCSSClasses } from '../js/utils';

/**
 * The menu consist of a main <ul> list containing the main menu
 * Then each menu (sports, destinations) has its own width of 100vw multiplied by the number of levels
 * Sports: 2 levels, Destinations: 3 levels
 * We then translate le container of the corresponding menu to view the correct level
 * Because Sports comes after destinations in the dom,
 * the sports container has to be multiplied by the number of levels
 * contained in the destinations menu, which is currently 3
 */
export default class extends Controller {
    static targets = [
        'rootInner', // top level parent div, used to expand menu to full height

        // LIST
        'menuListWrapper', // Top level div wrapper, display menu list when opened
        'mainList', // <ul> parent element of menu list
        // this is what we animate
        'menuLevelsDestinations', // container of multi level destinations
        'menuLevelsSports', // container of multi level sports

        // HEADER
        'burgerIcon',
        'closeIcon',
        'backButton',
        'menuHeader', // control header content (logo, or nav selection)
        'menuHeaderSelection', // header when user has selected submenu
        'menuHeaderSelectionName', // div tag where we put selected menu/submenu
    ];
    currentLevel = 0;
    selectedSlug = null;
    // Destinations|Sports depending on which menu is selected
    triggeredContainer = null;

    connect() {
        /* see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    // Open close the menu
    toggleOpenMenu(event) {
        event.preventDefault();

        const flashMessage = document.getElementById('front-banner-flash');
        toggleCSSClasses(document.body, 'overflow-hidden', 'fixed');
        if (flashMessage) toggleCSSClasses(flashMessage, 'hidden');

        const isOpenValue = this.burgerIconTarget.hidden;

        // svg icons
        this.burgerIconTarget.hidden = isOpenValue ? false : true;
        this.closeIconTarget.hidden = isOpenValue ? true : false;
        this.menuListWrapperTarget.hidden = isOpenValue ? true : false;

        // open menu full height
        toggleCSSClasses(this.rootInnerTarget, 'is-header-open');

        toggleCSSClasses(
            this.menuListWrapperTarget,
            'flex',
            'flex-1',
            'w-full',
        );

        toggleCSSClasses(this.menuHeaderTarget, 'absolute', 'relative');

        if (!window.location.pathname.startsWith('/mon-compte')) {
            toggleCSSClasses(
                this.menuHeaderTarget,
                'fill-white',
                'fill-content-neutral',
            );
        }
    }

    /**
     *  Select in which multi level menu we are going to
     */
    selectLevelContainer(event) {
        const title = event.currentTarget.dataset.title;
        this.triggeredContainer = this[`menuLevels${title}Target`];

        // slide out main menu to the left, out of the viewport
        this.mainListTarget.style.transform = `translateX(-100vw)`;
        this.currentLevel += 1;
        // slide in main container of multi-level menu
        this.triggeredContainer.style.transform = `translateX(-100vw)`;

        //change header
        toggleCSSClasses(this.menuHeaderTarget, 'hidden');
        toggleCSSClasses(this.menuHeaderSelectionTarget, 'hidden', 'flex');
        this.menuHeaderSelectionNameTarget.innerText = title;
        this.backButtonTarget.style.display = 'block';
    }

    /**
     *  Select the level of the multi level
     *  We use the slug to hide/show the corresponding <ul> list of the selected level
     */
    selectLevel(event) {
        this.currentLevel += 1;
        this.selectedSlug = event.currentTarget.dataset.targetedSlug;

        const levelList = document.querySelector(
            '[data-slug="' + this.selectedSlug + '"]',
        );
        if (levelList) {
            levelList.classList.remove('hidden', 'flex');
            levelList?.classList.add('flex');
        }

        // animate menu to selected level
        this.triggeredContainer.style.transform = `translateX(calc(-100vw*${this.currentLevel}))`;
    }

    goBack() {
        this.currentLevel--;
        if (this.currentLevel > 0) {
            // animate back one level
            this.triggeredContainer.style.transform = `translateX(calc(-100vw*${this.currentLevel}))`;
            // Hide back button if back to first level
            this.backButtonTarget.style.display =
                this.currentLevel > 0 ? 'block' : 'none';
        } else {
            this.mainListTarget.style.transform = `translateX(0)`;
            // slide in main container of multi-level menu
            this.triggeredContainer.style.transform = `translateX(0)`;

            this.resetHeader();
        }

        // header update
        if (this.currentLevel === 1) {
            this.menuHeaderSelectionNameTarget.innerText = 'Destinations';
        }
    }

    resetHeader() {
        // reset header
        toggleCSSClasses(this.menuHeaderTarget, 'hidden');
        toggleCSSClasses(this.menuHeaderSelectionTarget, 'hidden', 'flex');
        this.menuHeaderSelectionNameTarget.innerText = '';
    }
}
