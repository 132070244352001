import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['paxFirstName', 'paxLastName'];

    setPaxFirstName({ target: { value: firstName } }) {
        if (!this.hasPaxFirstNameTarget) {
            return;
        }

        this.paxFirstNameTarget.value = firstName;
    }

    setPaxLastName({ target: { value: lastName } }) {
        if (!this.hasPaxLastNameTarget) {
            return;
        }

        this.paxLastNameTarget.value = lastName;
    }
}
