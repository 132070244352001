import { Controller } from '@hotwired/stimulus';
import moment from 'moment';
import Pikaday from 'pikaday';
import pikdayI18n from '../js/legacy/front/js/src/ui/pikaday-i18n';

export default class extends Controller {
    static values = { dates: String };
    static targets = ['input', 'inputSubmitted', 'container'];

    initialize() {
        this.disabledDates = JSON.parse(this.datesValue);
        const parent = this;

        if (this.hasInputTarget && this.datesValue) {
            new Pikaday({
                ...window.app.config.pikaday,
                field: this.inputTarget,
                i18n: pikdayI18n[window.app.config.lang],
                firstDay: window.app.config.pikaday.firstDay,
                format: window.app.config.pikaday.format,
                minDate: new Date(),
                container: this.hasContainerTarget
                    ? this.containerTarget
                    : undefined,
                disableDayFn: function (date) {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);

                    if (date.getTime() === today.getTime()) {
                        return true;
                    }

                    for (const disabledDate of parent.disabledDates) {
                        const $startDate = new Date(disabledDate.startDate);
                        $startDate.setHours(0, 0, 0, 0);
                        const $endDate = new Date(disabledDate.endDate);
                        $endDate.setHours(0, 0, 0, 0);
                        if (date >= $startDate && date <= $endDate) {
                            return true;
                        }
                    }
                },
                onOpen: function () {
                    const $disabledDatesInputs = $('.is-disabled');
                    const $stopSaleSnackbar = $('#snackbar');
                    $disabledDatesInputs.hover(
                        function (d) {
                            $stopSaleSnackbar.removeClass('hidden');
                        },
                        function (d) {
                            $stopSaleSnackbar.addClass('hidden');
                        },
                    );

                    const firstAvailableDate = parent.getFirstAvailableDate();

                    if (firstAvailableDate) {
                        this.gotoDate(firstAvailableDate);
                    }
                },
                // Allow for hover even when month is changed
                onDraw: function () {
                    const $disabledDatesInputs = $('.is-disabled');
                    const $stopSaleSnackbar = $('#snackbar');
                    $disabledDatesInputs.hover(
                        function (d) {
                            $stopSaleSnackbar.removeClass('hidden');
                        },
                        function (d) {
                            $stopSaleSnackbar.addClass('hidden');
                        },
                    );
                },
                onSelect: () => {
                    this.inputSubmittedTarget.value = moment(
                        this.inputTarget.value,
                        'DD/MM/YYYY',
                    ).format('YYYY-MM-DD');
                },
            });
            // $(this.inputTarget).pikaday({
            //     container: this.hasContainerTarget
            //         ? this.containerTarget
            //         : undefined,
            //     disableDayFn: function (date) {
            //         const today = new Date();
            //         today.setHours(0, 0, 0, 0);
            //
            //         if (date.getTime() === today.getTime()) {
            //             return true;
            //         }
            //
            //         for (const disabledDate of parent.disabledDates) {
            //             const $startDate = new Date(disabledDate.startDate);
            //             $startDate.setHours(0, 0, 0, 0);
            //             const $endDate = new Date(disabledDate.endDate);
            //             $endDate.setHours(0, 0, 0, 0);
            //             if (date >= $startDate && date <= $endDate) {
            //                 return true;
            //             }
            //         }
            //     },
            //     onOpen: function () {
            //         const $disabledDatesInputs = $('.is-disabled');
            //         const $stopSaleSnackbar = $('#snackbar');
            //         $disabledDatesInputs.hover(
            //             function (d) {
            //                 $stopSaleSnackbar.removeClass('hidden');
            //             },
            //             function (d) {
            //                 $stopSaleSnackbar.addClass('hidden');
            //             },
            //         );
            //
            //         const firstAvailableDate = parent.getFirstAvailableDate();
            //
            //         if (firstAvailableDate) {
            //             this.gotoDate(firstAvailableDate);
            //         }
            //     },
            //     // Allow for hover even when month is changed
            //     onDraw: function () {
            //         console.log('draw');
            //         const $disabledDatesInputs = $('.is-disabled');
            //         const $stopSaleSnackbar = $('#snackbar');
            //         $disabledDatesInputs.hover(
            //             function (d) {
            //                 $stopSaleSnackbar.removeClass('hidden');
            //             },
            //             function (d) {
            //                 $stopSaleSnackbar.addClass('hidden');
            //             },
            //         );
            //     },
            //     onSelect: () => {
            //         this.inputSubmittedTarget.value = moment(
            //             this.inputTarget.value,
            //             'DD/MM/YYYY',
            //         ).format('YYYY-MM-DD');
            //     },
            // });
        }
    }

    getFirstAvailableDate() {
        const currentDate = new Date();

        const sortedDisabledDates = this.disabledDates.sort(
            (a, b) => new Date(a.endDate) - new Date(b.endDate),
        );

        const nextDay = 24 * 60 * 60 * 1000;
        let firstAvailableDate = null;

        // if current date is before the first closed period
        if (
            sortedDisabledDates.length > 0 &&
            currentDate < new Date(sortedDisabledDates[0].startDate)
        ) {
            return new Date(currentDate.getTime() + nextDay);
        }

        for (let i = 0; i < sortedDisabledDates.length; i++) {
            const endDate = new Date(sortedDisabledDates[i].endDate);
            const nextStartDate = sortedDisabledDates[i + 1]
                ? new Date(sortedDisabledDates[i + 1].startDate)
                : null;

            if (
                (nextStartDate && endDate < nextStartDate) ||
                (!nextStartDate && endDate > currentDate)
            ) {
                firstAvailableDate = new Date(endDate.getTime() + nextDay);
                break;
            }
        }

        if (!firstAvailableDate && sortedDisabledDates.length > 0) {
            const lastEndDate = new Date(
                sortedDisabledDates[sortedDisabledDates.length - 1].endDate,
            );
            firstAvailableDate = new Date(
                lastEndDate.getFullYear(),
                lastEndDate.getMonth() + 1,
                1,
            );
        }

        return firstAvailableDate;
    }
}
