import { Controller } from '@hotwired/stimulus';
import { toggleCSSClasses } from '../js/utils';

export default class extends Controller {
    static targets = [
        'maxAmountContainer',
        'maxAmountText',
        'payment',
        'method',
    ];

    connect() {
        this.toggleField();
    }

    toggleField() {
        if (this.hasPaymentTarget) {
            if (
                this.methodTarget.options[this.methodTarget.selectedIndex]
                    .dataset.method === 'onepay'
            ) {
                ('ici');
                this.showField(this.paymentTarget);
                this.updateMaxAmount();
            } else {
                this.hideField(this.paymentTarget);
                this.resetAmount();
            }
        }
    }

    showField(field) {
        field.classList.remove('hidden');
    }

    hideField(field) {
        field.classList.add('hidden');
    }

    resetAmount() {
        this.maxAmountContainerTarget.classList.add('hidden');
        this.maxAmountContainerTarget.classList.remove('flex');
    }

    updateMaxAmount() {
        // Info svg
        const selectPayment = this.paymentTarget.querySelector('select');
        this.maxAmountContainerTarget.classList.add('flex');
        this.maxAmountContainerTarget.classList.remove('hidden');
        this.maxAmountTextTarget.innerHTML = `Maximum : ${
            selectPayment.options[selectPayment.selectedIndex].dataset.amount /
            100
        }€`;
    }
}
