import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['text', 'button'];

    static values = {
        limit: Number,
    };

    static open = false;

    connect() {
        this.textValue = this.textTarget.innerHTML;
        // trim useless spaces in text
        this.textValue = this.textValue.replace(/\s+/g, ' ');

        // Hide button if words count is less or equal than the limit
        if (this.textValue.split(' ').length <= this.limitValue) {
            this.buttonTarget.classList.add('hidden');
            return;
        }
        this.textTarget.innerHTML = this.truncate(this.textValue);
    }

    toggle() {
        if (!this.hasTextTarget) {
            return;
        }

        this.open = !this.open;

        if (this.textValue.split(' ').length <= this.limitValue) {
            return;
        }

        if (!this.open) {
            this.textTarget.innerHTML = this.truncate(this.textValue);
            this.buttonTarget.innerText = 'Voir plus';
        } else {
            this.textTarget.innerHTML = this.textValue;
            this.buttonTarget.innerText = 'Voir moins';
        }
    }

    truncate(text) {
        return text.split(' ').splice(0, this.limitValue).join(' ') + '...';
    }
}
