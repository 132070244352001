import { Controller } from '@hotwired/stimulus';
import 'quill/dist/quill.snow.css';

import Quill from 'quill/core';

import Toolbar from 'quill/modules/toolbar';
import Snow from 'quill/themes/snow';

import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Underline from 'quill/formats/underline';
import Header from 'quill/formats/header';
import List from 'quill/formats/list';
import Link from 'quill/formats/link';

Quill.register({
    'modules/toolbar': Toolbar,
    'themes/snow': Snow,
    'formats/bold': Bold,
    'formats/underline': Underline,
    'formats/italic': Italic,
    'formats/header': Header,
    'formats/list': List,
    'formats/link': Link,
});
export default class extends Controller {
    static targets = ['editor'];

    connect() {
        if (this.hasEditorTarget) {
            const div = document.createElement('div');
            div.innerHTML = this.editorTarget.value;
            this.editorTarget.style.display = 'none';
            this.editorTarget.parentNode.insertBefore(
                div,
                this.editorTarget.nextSibling,
            );

            const q = new Quill(div, {
                theme: 'snow',
            });
            let element = this.editorTarget;
            q.on('text-change', function () {
                if (q.getLength() !== 1) {
                    element.value = q.root.innerHTML;
                } else {
                    element.value = null;
                }
            });
        }
    }
}
