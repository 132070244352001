import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['button'];

    connect() {
        // browser back button, restore  button intial state
        window.addEventListener(
            'pageshow',
            function (event) {
                if (event.persisted && this.hasButtonTarget) {
                    //check if buttonTarget has a spinner id element and remove  it
                    if (this.buttonTarget.querySelector('#spinner')) {
                        this.buttonTarget.querySelector('#spinner').remove();
                    }
                    // unhide other child of buttonTarget
                    this.buttonTarget.childNodes.forEach((child) => {
                        if (child.nodeType === Node.ELEMENT_NODE) {
                            child.classList.remove('hidden');
                        }
                    });
                    this.buttonTarget.disabled = false;
                }
            }.bind(this),
        );
    }

    submit() {
        if (this.hasButtonTarget) {
            // Loop through every child element and hide them
            this.buttonTarget.childNodes.forEach((child) => {
                if (child.nodeType === Node.ELEMENT_NODE) {
                    child.classList.add('hidden');
                }
            });

            // Create a new spinner element
            const spinner = document.createElement('span');
            spinner.id = 'spinner';
            spinner.className =
                'inline-block h-m w-m animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]';
            spinner.innerHTML =
                '<span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"></span>';
            // Append the spinner to the button
            this.buttonTarget.appendChild(spinner);

            this.buttonTarget.disabled = true;
        }
    }
}
