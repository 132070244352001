import { Controller } from '@hotwired/stimulus';
import Splide from '@splidejs/splide';

export default class extends Controller {
    static targets = ['listItem'];

    connect() {
        if (this.hasListItemTarget) {
            // create a splidejs carousel for every listItem
            this.listItemTargets.forEach((element) => {
                new Splide(element, {
                    perPage: 1,
                    perMove: 1,
                    rewind: true,
                    rewindByDrag: true,
                    pagination: true,
                }).mount();
            });
        }
    }
}
