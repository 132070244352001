import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['climate', 'dataRow', 'temperature', 'water'];

    connect() {
        this.enhanceTemperatureWaterCells();
    }

    enhanceTemperatureWaterCells() {
        const config = {
            temperature: {
                range: { min: -10, max: 30 },
                colors: [
                    '#3643BA',
                    '#4dbeea',
                    '#beefdd',
                    '#eed8c4',
                    '#ffc474',
                    '#faa656',
                ],
            },
            water: {
                range: { min: 0, max: 30 },
                colors: [
                    '#3643BA',
                    '#4dbeea',
                    '#beefdd',
                    '#eed8c4',
                    '#ffc474',
                    '#faa656',
                ],
            },
        };

        this.temperatureTargets.forEach((temp) => {
            const val = parseInt(temp.dataset.val, 10) || 0;
            const pct = Math.max(
                0,
                Math.min(
                    1,
                    (val - config.temperature.range.min) /
                        (config.temperature.range.max -
                            config.temperature.range.min),
                ),
            );
            const idx = Math.round(
                pct * (config.temperature.colors.length - 1),
            );
            temp.style.backgroundColor = config.temperature.colors[idx];
        });

        this.waterTargets.forEach((water) => {
            const val = parseInt(water.dataset.val, 10) || 0;
            const pct = Math.max(
                0,
                Math.min(
                    1,
                    (val - config.water.range.min) /
                        (config.water.range.max - config.water.range.min),
                ),
            );
            const idx = Math.round(pct * (config.water.colors.length - 1));
            water.querySelector('.icon').style.color = config.water.colors[idx];
        });
    }
}
