import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['section'];

    toggle(event) {
        event.preventDefault();
        // remove all active link and update current active link
        const parentUl = event.target.parentNode.parentNode;
        for (let child of parentUl.children) {
            child.classList.remove('is-active');
        }
        event.target.parentNode.classList.add('is-active');

        // show section
        this.sectionTargets.forEach((target) => {
            target.classList.remove('flex');
            target.classList.add('hidden');
            if (target.id === event.params.id) {
                target.classList.add('flex');
                target.classList.remove('hidden');
            }
        });
    }
}
