import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['loginOpenModal'];

    connect() {
        // get current url
        this.originalUrl = window.location.href;
        // open login modal when page is mounted
        this.loginOpenModalTarget.click();
        this.handleOpenModal();
    }

    //  Used for Analytics
    handleOpenModal() {
        window.history.replaceState(null, '', '/reservation/connexion');
    }
    handleDismissModal() {
        window.history.replaceState(null, '', this.originalUrl);
    }
}
