import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'feedbackBox',
        'form',
        'success',
        'content',
        'input',
        'submit',
    ];

    inputMessageErrorClasses = [
        'border',
        'border-solid',
        'border-status-negative',
        'text-center',
        'text-sm',
        'text-status-negative',
    ];

    submitFeedback(event) {
        event.preventDefault();

        this.inputTarget.classList.remove(...this.inputMessageErrorClasses);

        fetch(event.target.action, {
            method: event.target.method,
            body: new FormData(event.target),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return Promise.reject(response);
            })
            .then((data) => {
                this.clear();
                this.contentTarget.classList.add('hidden');
                this.successTarget.classList.remove('hidden');
            })
            .catch((response) => {
                console.error(response);

                response.json().then(() => {
                    this.handleError(this.inputTarget);
                    this.inputTarget.classList.add(
                        ...this.inputMessageErrorClasses,
                    );
                });
            });
    }

    submitDecline(event) {
        event.preventDefault();

        fetch(event.target.action, {
            method: event.target.method,
            body: new FormData(event.target),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return Promise.reject(response);
            })
            .then((data) => {
                this.feedbackBoxTarget.replaceWith(data);
            })
            .catch((response) => {
                console.error(response);
                response.json().then(() => {
                    this.handleError(this.feedbackBoxTarget);
                });
            });
    }

    clear() {
        document
            .querySelectorAll('.form__message')
            .forEach((message) => message.classList.add('hidden'));
        document
            .querySelectorAll('.form__error')
            .forEach((error) => error.classList.add('hidden'));
    }

    reset() {
        if (
            this.hasContentTarget &&
            this.contentTarget.classList.contains('hidden')
        ) {
            this.contentTarget.classList.remove('hidden');
        }

        if (
            this.hasSuccessTarget &&
            !this.successTarget.classList.contains('hidden')
        ) {
            if (this.hasFormTarget) {
                this.formTarget.reset();
            }
            this.successTarget.classList.add('hidden');
        }
    }

    handleError(target) {
        const errorMessage = document.createElement('p');
        errorMessage.id = 'error';
        errorMessage.classList.add(
            'form__message',
            ...this.inputMessageErrorClasses,
        );
        errorMessage.innerText =
            'Une erreur est survenue. Merci de vérifier les informations saisies.';

        const existingError = document.getElementById('error');

        if (existingError) {
            existingError.replaceWith(errorMessage);
            return;
        }

        target.after(errorMessage);
    }

    validate() {
        const input = this.inputTarget;
        const length = input.value.length;
        const maxLength = input.maxLength;

        if (length === 0 || length > maxLength) {
            this.submitTarget.disabled = true;
            return;
        }

        this.submitTarget.disabled = false;
    }
}
