import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [];

    connect() {
        this.element.addEventListener('mouseenter', this.addHover.bind(this));
        this.element.addEventListener(
            'mouseleave',
            this.removeHover.bind(this),
        );
        this.element.addEventListener('focus', this.addFocus.bind(this));
        this.element.addEventListener('blur', this.removeFocus.bind(this));
    }

    addHover(event) {
        event.currentTarget.setAttribute('data-hover', '');
    }

    removeHover(event) {
        event.currentTarget.removeAttribute('data-hover');
    }

    addFocus(event) {
        event.currentTarget.setAttribute('data-focus', '');
    }

    removeFocus(event) {
        event.currentTarget.removeAttribute('data-focus');
    }
}
