import { Controller } from '@hotwired/stimulus';
import dayjs from 'dayjs';

// Allow to manipulate custom date format (eg: dd/mm/yyyy)
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

export default class extends Controller {
    static targets = [
        'travellers',
        'startDate',
        'endDate',
        'numberOfNights',
        'totalPrice',
    ];

    update({
        detail: { travellers, startDate, endDate, unitPrice, dateFormat },
    }) {
        const numberOfNights = this.getNumberOfNights(
            startDate,
            endDate,
            dateFormat,
        );

        this.startDateTarget.innerText = dayjs(startDate, dateFormat).format(
            'DD/MM/YYYY',
        );
        this.endDateTarget.innerText = dayjs(endDate, dateFormat).format(
            'DD/MM/YYYY',
        );
        this.numberOfNightsTarget.innerText = `${numberOfNights} nuit${
            numberOfNights > 1 ? 's' : ''
        }`;
        this.travellersTarget.innerText = `${travellers} personne${
            travellers > 1 ? 's' : ''
        }`;

        if (parseInt(unitPrice) > 0 && this.totalPriceTarget) {
            this.totalPriceTargets.forEach((totalPriceTarget) => {
                totalPriceTarget.innerText =
                    (unitPrice * travellers) / 100 + ' €';
            });
        }
    }

    getNumberOfNights(startDate, endDate, dateFormat) {
        return dayjs(endDate, dateFormat).diff(
            dayjs(startDate, dateFormat),
            'day',
            true,
        );
    }
}
