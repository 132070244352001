import { Controller } from '@hotwired/stimulus';
import Splide from '@splidejs/splide';

export default class extends Controller {
    static values = {
        id: String,
    };

    splide;

    connect() {
        this.splide = new Splide(`#${this.idValue}`, {
            focus: 'center',
            breakpoints: {
                768: {
                    start: 0,
                    perMove: 1,
                },
            },
            autoWidth: true,
            autoHeight: true,
            gap: '16px',
            padding: '0',
            arrows: false,
            pagination: false,
            flickPower: 100,
            rewind: true,
            rewindSpeed: 1000,
            rewindByDrag: true,
        }).mount();
    }

    next = () => {
        this.splide.go('>');
    };

    prev = () => {
        this.splide.go('<');
    };
}
