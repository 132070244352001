import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['checkbox'];

    static values = {
        switchUrl: String,
        csrf: String,
    };

    async switch(event) {
        try {
            let response = await fetch(this.switchUrlValue, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    visible: this.checkboxTarget.checked,
                    _token: this.csrfValue,
                }),
            });
        } catch (error) {
            console.error(error);
        }
    }
}
