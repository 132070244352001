import { Controller } from '@hotwired/stimulus';
import moment from 'moment';

export default class extends Controller {
    static targets = [
        'startDate',
        'timeToDeparture',
        'cancellationDate',
        'reason',

        'a30days',
        'u30days',
    ];

    connect() {
        this.refreshDateHelper();
    }

    refreshDateHelper() {
        const cancellationDate = moment(
            this.cancellationDateTarget.value,
            'YYYY-MM-DD',
        );

        const startdate = moment(this.startDateTarget.innerText, 'DD/MM/YYYY');
        const diff = startdate.diff(cancellationDate, 'days');
        this.timeToDepartureTarget.innerText = diff;

        // close all
        this.a30daysTarget.classList.remove('block');
        this.a30daysTarget.classList.add('hidden');

        this.u30daysTarget.classList.remove('block');
        this.u30daysTarget.classList.add('hidden');

        if (diff > 30) {
            this.a30daysTarget.classList.remove('hidden');
            this.a30daysTarget.classList.add('block');
        } else {
            this.u30daysTarget.classList.remove('hidden');
            this.u30daysTarget.classList.add('block');
        }
    }

    handleSelectedOrigin(event) {
        this.reasonTarget.childNodes.forEach((child) => {
            child.hidden =
                child.dataset.origin != null &&
                child.dataset.origin !== event?.target?.value;
        });
        this.reasonTarget.value = '';
    }
}
