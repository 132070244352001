import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['input', 'element'];
    static values = {};

    activeClass(event) {
        const targets = this.elementTargets;

        for (let i = 0; i < targets.length; i++) {
            const inputChild = targets[i].getElementsByTagName('input')[0];
            let classListEl = targets[i].classList;

            if (inputChild.checked) {
                classListEl.add('ski-view__active');
                continue;
            }

            if (classListEl.contains('ski-view__active')) {
                classListEl.remove('ski-view__active');
            }
        }
    }
}
