import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['wrapper', 'item'];

    constructor(context) {
        super(context);

        this.mouseDown = false;
        this.startX = 0;
        this.scrollLeft = 0;
    }

    connect() {
        this.itemTargets.forEach((item) => {
            // Mobile
            item.addEventListener('touchmove', (e) => this.drag(e));
            item.addEventListener('touchstart', (e) => this.startDragging(e));
            item.addEventListener('touchend', (e) => this.stopDragging(e));

            // Desktop
            item.addEventListener('mousemove', (e) => this.drag(e));
            item.addEventListener(
                'mousedown',
                (e) => this.startDragging(e),
                false,
            );
            item.addEventListener(
                'mouseup',
                (e) => this.stopDragging(e),
                false,
            );
            item.addEventListener(
                'mouseleave',
                (e) => this.stopDragging(e),
                false,
            );
        });
    }

    drag(e) {
        if (e.cancelable) {
            e.preventDefault();
            e.stopImmediatePropagation();
        }

        if (!this.mouseDown) {
            return;
        }

        let pageX = e.pageX;

        if (!e.pageX) {
            pageX = e.touches[0].pageX;
        }

        const x = pageX - this.wrapperTarget.offsetLeft;
        const scroll = x - this.startX;

        this.wrapperTarget.scrollLeft = this.scrollLeft - scroll;
    }

    startDragging(e) {
        let pageX = e.pageX;

        if (!e.pageX) {
            pageX = e.touches[0].pageX;
        }

        this.mouseDown = true;
        this.startX = pageX - this.wrapperTarget.offsetLeft;
        this.scrollLeft = this.wrapperTarget.scrollLeft;
    }

    stopDragging(e) {
        this.mouseDown = false;
    }
}
