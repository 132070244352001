import { Controller } from '@hotwired/stimulus';
import { toggleCSSClasses } from '../js/utils';

export default class extends Controller {
    static targets = [
        'navLink',
        'menuDestinations',
        'menuSports',
        'menuConcept',
    ];

    connect() {
        // On click anywhere else than banner, close menu
        document.body.addEventListener('click', (event) => {
            event.stopPropagation();

            if (
                !this.element.contains(event.target) &&
                this.hasMenuDestinationsTarget &&
                !this.menuDestinationsTarget.contains(event.target) &&
                this.hasMenuSportsTarget &&
                !this.menuSportsTarget.contains(event.target) &&
                this.hasMenuConceptTarget &&
                !this.menuConceptTarget.contains(event.target)
            ) {
                this.closeAllMenus();
            }
        });
    }

    /**
     * Open/close menu
     * @param event.params.ref
     * targetValue can be menuDestinations, menuSports, menuConcept
     */
    openMenuLevel1(event) {
        event.preventDefault();
        const targetValue = event.params.ref;

        // close all except toggled menu
        this.closeAllMenus(targetValue);

        // toggle targeted menu
        toggleCSSClasses(this[targetValue + 'Target'], 'hidden');
        toggleCSSClasses(event.target, 'font-semibold', 'underline');
    }

    /**
     * Close all menus except the one currently displayed
     * @param targetedMenu menuXXXXX
     */
    closeAllMenus(targetedMenu) {
        if (targetedMenu !== 'menuDestinations') {
            this.menuDestinationsTarget.classList.add('hidden');
        }
        if (
            targetedMenu !== 'menuSports' &&
            !this.menuSportsTarget.classList.contains('hidden')
        ) {
            this.menuSportsTarget.classList.add('hidden');
        }
        if (
            targetedMenu !== 'menuConcept' &&
            !this.menuConceptTarget.classList.contains('hidden')
        ) {
            this.menuConceptTarget.classList.add('hidden');
        }

        this.navLinkTargets.forEach((navLink) => {
            navLink.classList.remove('font-semibold', 'underline');
        });

        // bulk query all menus that are opened and hide + remove their data attributes
        document
            .querySelectorAll(`[data-focused-level2] , [data-focused-level3]`)
            .forEach((section) => {
                section.classList.remove('text-content-brand', 'font-semibold');
                section.removeAttribute(`data-focused-level2`);
                section.removeAttribute(`data-focused-level3`);
            });
        document
            .querySelectorAll(` [data-level2-opened], [data-level3-opened]`)
            .forEach((section) => {
                section.classList.add('hidden');
                section.removeAttribute('data-level-2-opened');
                section.removeAttribute('data-level-3-opened');
            });
    }

    /**
     * Open destination submenu level 2
     * @param event
     */
    openMenuLevel(event) {
        const slug = event.params.slug;
        const level = event.params.level;

        document
            .querySelectorAll(`[data-focused-level${level}]`)
            .forEach((btn) =>
                btn.classList.remove('text-content-brand', 'font-semibold'),
            );
        delete event.currentTarget.dataset[`focused-level${level}`];
        this.closeLevel3Destinations();
        if (level === 2) {
            this.closeLevel2Destinations();
        }

        // show clicked level X section using slug id
        const section = document.getElementById(`level${level}-${slug}`);
        section?.classList.remove('hidden');
        // add levelX-opened data attribute to the section
        section?.setAttribute(`data-level${level}-opened`, '');

        event.currentTarget.classList.add(
            'font-semibold',
            'text-content-brand',
        );
        event.currentTarget.setAttribute(`data-focused-level${level}`, '');
    }

    /**
     *  close opened destinations submenus level3 or 2 using levelXOpened data attribute
     */
    closeLevel2Destinations() {
        document
            .querySelectorAll('[data-level2-opened]')
            .forEach((level2Section) => {
                level2Section.classList.add('hidden');
                delete level2Section.dataset.level2Opened;
            });
    }

    closeLevel3Destinations() {
        document
            .querySelectorAll('[data-level3-opened]')
            .forEach((level3Section) => {
                level3Section.classList.add('hidden');
                delete level3Section.dataset.level2Opened;
            });
    }
}
