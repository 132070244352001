import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['inputs'];

    triggerClick(event) {
        const inputTarget = event.currentTarget.dataset.triggerClick;
        event.preventDefault();
        const item = document.querySelector(`[data-trigger-id=${inputTarget}]`);
        item.click();
    }

    triggerSubmit(event) {
        const item = event.target;
        let formData = new FormData();
        if (item.files.length === 0) {
            return;
        }

        formData.append('upload_file', item.files[0]);
        if (item.parentElement.nodeName !== 'FORM') {
            return;
        }

        const url = item.parentElement.action.split('/');
        const element = document.getElementById(url.at(-1));

        fetch(item.parentElement.action, {
            method: 'POST',
            body: formData,
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Erreur lors de la requête.');
                }
            })
            .then((data) => {
                element.innerHTML = data.html;
            })
            .catch((error) => {
                const link = element.querySelector('a');
                link.after(error);
            });
    }

    removePicture(event) {
        event.preventDefault();
        const item = event.currentTarget;

        if (!confirm(item.dataset.confirm)) {
            return false;
        }

        fetch(item.href, {
            method: 'GET',
        })
            .then((response) => {
                if (response.ok) {
                    item.parentElement.remove();
                } else {
                    throw new Error('Erreur lors de la requête.');
                }
            })
            .catch((error) => {
                item.after(error);
            });
    }
}
