import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['dropzone', 'input', 'form'];

    connect() {
        this.dropzoneTarget.addEventListener(
            'dragover',
            this.handleDragOver.bind(this),
        );
        this.dropzoneTarget.addEventListener(
            'drop',
            this.handleDrop.bind(this),
        );
        this.dropzoneTarget.addEventListener(
            'dragleave',
            this.handleDragLeave.bind(this),
        );
    }

    handleDragOver(event) {
        event.preventDefault();
        this.dropzoneTarget.classList.add('add-picture__loader--active');
    }

    handleDrop(event) {
        event.preventDefault();
        this.dropzoneTarget.classList.remove('add-picture__loader--active');

        const files = event.dataTransfer.files;

        if (!files.length > 0) {
            return;
        }

        this.inputTarget.files = files;
        this.formTarget.submit();

        const loader = document.createElement('span');
        loader.classList.add('add-picture__loader');
        this.dropzoneTarget.replaceChildren(loader);
    }

    handleDragLeave(event) {
        this.dropzoneTarget.classList.remove('add-picture__loader--active');
    }
}
