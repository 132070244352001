import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'infobox',
        'message',
        'addButton',
        'input',
        'substractButton',
    ];
    static values = {
        max: Number,
    };

    connect() {
        this.updateMessage();
    }

    add() {
        this.updateMessage();
    }

    substract() {
        this.updateMessage();
    }

    update() {
        this.updateMessage();
    }

    updateMessage() {
        let currentValue = 0;

        for (const inputTarget of this.inputTargets) {
            currentValue = currentValue + parseInt(inputTarget.value);

            if (inputTarget.value > 0) {
                this.substractButtonTargets.forEach((btn) => {
                    btn.disabled = false;
                });
            }
        }

        let remaining = this.maxValue - currentValue;

        if (this.hasMessageTarget) {
            this.messageTarget.innerHTML =
                'Vous avez sélectionné ' +
                currentValue +
                ' formules, vous devez encore en choisir ' +
                remaining +
                ' (Vous devez sélectionner autant de formules que vous êtes de voyageurs)';
        }

        if (0 === remaining) {
            this.addButtonTargets.forEach((btn) => {
                btn.disabled = true;
            });
            this.inputTargets.forEach((input) => {
                input.setAttribute('readonly', '');
            });
            if (this.hasInfoboxTarget) {
                this.infoboxTarget.classList.remove('message');
                this.infoboxTarget.classList.add('hidden');
            }
        } else {
            this.addButtonTargets.forEach((btn) => {
                btn.disabled = false;
            });
            this.inputTargets.forEach((input) => {
                input.removeAttribute('readonly');
            });
            if (this.hasInfoboxTarget) {
                this.infoboxTarget.classList.add('message');
                this.infoboxTarget.classList.remove('hidden');
            }
        }
    }
}
