import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'modal',
        'input',
        'error',
        'success',
        'content',
        'form',
        'send',
    ];

    async submit(event) {
        event.preventDefault();

        this.sendTarget.setAttribute('disabled', 'disabled');

        await fetch(event.target.action, {
            method: event.target.method,
            body: new FormData(event.target),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return Promise.reject(response);
            })
            .then((response) => {
                this.clear();
                this.contentTarget.classList.add('hidden');
                this.successTarget.classList.remove('hidden');
            })
            .catch((response) => {
                response.json().then((error) => {
                    this.clear();
                    this.sendTarget.removeAttribute('disabled');
                    this.errorTarget.classList.remove('hidden');
                });
            });
    }

    clear() {
        document
            .querySelectorAll('.form__message')
            .forEach((message) => message.classList.add('hidden'));
        document
            .querySelectorAll('.form__error')
            .forEach((error) => error.classList.add('hidden'));
    }

    reset() {
        if (
            this.hasContentTarget &&
            this.contentTarget.classList.contains('hidden')
        ) {
            this.contentTarget.classList.remove('hidden');
        }

        if (this.hasSendTarget && this.sendTarget.hasAttribute('disabled')) {
            this.sendTarget.removeAttribute('disabled');
        }

        if (
            this.hasSuccessTarget &&
            !this.successTarget.classList.contains('hidden')
        ) {
            if (this.hasFormTarget) {
                this.formTarget.reset();
            }
            this.successTarget.classList.add('hidden');
        }
    }
}
