import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['open', 'close', 'overlay'];
    static outlets = ['modal'];
    static values = {};

    constructor(context) {
        super(context);
        this.body = document.querySelector('body');
    }

    open() {
        this.modalOutletElement.classList.remove('hidden');
        const event = new CustomEvent('modal-open', this.modalOutletElement);
        window.dispatchEvent(event);
        this.body.style.overflow = 'hidden';
    }

    close() {
        this.modalOutletElement.classList.add('hidden');
        const event = new CustomEvent('modal-close', this.modalOutletElement);
        window.dispatchEvent(event);
        this.body.style.overflow = 'auto';
    }

    handleClickOutside(event) {
        if (event.target === this.overlayTarget) {
            this.close();
        }
    }
}
