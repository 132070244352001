import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'button',
        'nbAdults',
        'nbChildren',
        'childrenDetails',
        'childrenAgeInput',
        'childrenAgeForm',
    ];

    // Define the oldValue to handle +/- inputs
    nbChildrenTargetConnected(element) {
        element.oldValue = parseInt(element.value || 0);
    }

    refreshButtonText() {
        this.buttonTarget.innerHTML = `${this.nbAdultsTarget.value} adulte(s) - ${this.nbChildrenTarget.value} enfant(s)`;
    }

    onNbAdultsChange(event) {
        this.refreshButtonText();
        if (this.nbAdultsTarget.getAttribute('maxstock')) {
            this.onPaxChange();
        }
    }

    onNbChildrenChange(event) {
        const oldNbChildren = this.nbChildrenTarget.oldValue;
        const nbChildren = isNaN(parseInt(event.target.value))
            ? 0
            : parseInt(event.target.value);

        this.refreshButtonText();

        const hasIncreasedChildren = nbChildren > oldNbChildren;

        if (hasIncreasedChildren) {
            const nbChildrenToAdd = nbChildren - oldNbChildren;

            for (let i = 1; i <= nbChildrenToAdd; i++) {
                this.addChildrenAgeInput();
            }
        }

        const hasDecreasedChildren = nbChildren < oldNbChildren;

        if (hasDecreasedChildren) {
            const nbChildrenToRemove = oldNbChildren - nbChildren;

            for (let i = 1; i <= nbChildrenToRemove; i++) {
                this.removeChildrenAgeInput();
            }
        }

        nbChildren === 0
            ? this.hideChildrenDetails()
            : this.showChildrenDetails();

        this.nbChildrenTarget.oldValue = nbChildren;
        if (this.nbAdultsTarget.getAttribute('maxstock')) {
            this.onPaxChange();
        }
    }

    onPaxChange(event) {
        let newMaxAdults =
            this.nbAdultsTarget.getAttribute('maxstock') -
            this.nbChildrenTarget.value;
        this.nbAdultsTarget.setAttribute('max', newMaxAdults);
        if (this.nbAdultsTarget.value > newMaxAdults) {
            this.nbAdultsTarget.value(newMaxAdults);
        }

        let newMaxChildren =
            this.nbAdultsTarget.getAttribute('maxstock') -
            this.nbAdultsTarget.value;
        this.nbChildrenTarget.setAttribute('max', newMaxChildren);
        if (this.nbChildrenTarget.value > newMaxChildren) {
            this.nbChildrenTarget.value(newMaxChildren);
        }
    }

    hideChildrenDetails() {
        this.childrenDetailsTarget.classList.add('is-hidden');
    }

    showChildrenDetails() {
        this.childrenDetailsTarget.classList.remove('is-hidden');
    }

    addChildrenAgeInput() {
        // Retrieve the template, duplicate it and append it inside the DOM
        const template = document.getElementById('childrenAgeInputTemplate');
        this.childrenAgeFormTarget.appendChild(
            document.importNode(template.content, true),
        );

        // Update last input label
        const lastChildrenAgeInput =
            this.childrenAgeInputTargets[
                this.childrenAgeInputTargets.length - 1
            ];
        lastChildrenAgeInput.getElementsByClassName(
            'pax-selector__placeholder',
        )[0].innerHTML = 'Âge enfant ' + this.childrenAgeInputTargets.length;
    }

    removeChildrenAgeInput() {
        if (this.hasChildrenAgeInputTarget) {
            const lastChildrenAgeInput =
                this.childrenAgeInputTargets[
                    this.childrenAgeInputTargets.length - 1
                ];
            lastChildrenAgeInput.remove();
        }
    }
}
