import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['labelRadio', 'controlRadio', 'inputRadio', 'spanRadio'];

    connect() {
        this.updateCheckedState();
        // handle default checked input value for forms with a pre selected value
        this.inputRadioTargets.forEach((inputRadio) => {
            const isChecked = inputRadio.checked;
            if (isChecked) {
                inputRadio.click();
            }
        });
    }

    toggleRadio() {
        this.updateCheckedState();
    }

    updateCheckedState() {
        this.inputRadioTargets.forEach((inputRadio, index) => {
            const isChecked = inputRadio.checked;
            const labelRadio = this.labelRadioTargets[index];
            const controlRadio = this.controlRadioTargets[index];
            const spanRadio = this.spanRadioTargets[index];

            if (isChecked) {
                labelRadio.dataset.checked = 'true';
                labelRadio.dataset.focus = 'true';
                controlRadio.dataset.checked = 'true';
                controlRadio.dataset.focus = 'true';
                spanRadio.dataset.checked = 'true';
                spanRadio.dataset.focus = 'true';
            } else {
                delete labelRadio.dataset.checked;
                delete labelRadio.dataset.focus;
                delete controlRadio.dataset.checked;
                delete controlRadio.dataset.focus;
                delete spanRadio.dataset.checked;
                delete spanRadio.dataset.focus;
            }
        });
    }
}
