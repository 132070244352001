import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['content', 'error', 'success', 'form', 'send'];

    submit(event) {
        event.preventDefault();

        const data = new FormData(event.target);

        // Do a bit of work to convert the entries to a plain JS object
        const value = Object.fromEntries(data.entries());
        this.sendTarget.setAttribute('disabled', 'disabled');
        fetch(event.target.action, {
            method: event.target.method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(value),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return Promise.reject(response);
            })
            .then((response) => {
                this.clear();
                this.contentTarget.classList.add('hidden');
                this.successTarget.classList.remove('hidden');
            })
            .catch((response) => {
                response.json().then((error) => {
                    this.sendTarget.removeAttribute('disabled');
                    this.clear();

                    // Generic error
                    const message = document.createElement('p');

                    message.id = 'error';
                    message.classList.add(
                        'error',
                        'text-status-negative',
                        'font-xs',
                        'mt-xs',
                        'italic',
                    );

                    message.innerText = error.message;

                    this.contentTarget.before(message);
                });
            });
    }

    clear() {
        document
            .querySelectorAll('.error')
            .forEach((message) => message.classList.add('hidden'));
    }

    reset() {
        if (this.hasContentTarget) {
            if (this.contentTarget.classList.contains('hidden')) {
                this.contentTarget.classList.remove('hidden');
            }
        }

        if (this.hasSendTarget && this.sendTarget.hasAttribute('disabled')) {
            this.sendTarget.removeAttribute('disabled');
        }

        if (
            this.hasSuccessTarget &&
            !this.successTarget.classList.contains('hidden')
        ) {
            if (this.hasFormTarget) {
                this.formTarget.reset();
            }

            this.successTarget.classList.add('hidden');
        }
    }
}
