import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['input', 'addButton', 'substractButton'];

    connect() {
        // reset + and - buttons when user selects another date
        // (reset made in travel-tour.js)
        this.inputTarget.addEventListener('reset', () => {
            this.substractButtonTarget.disabled = false;
            this.addButtonTarget.disabled = false;
        });

        this.inputTargets.forEach((input) => {
            if (input.value === 0 && this.hasSubstractButtonTarget) {
                this.substractButtonTarget.disabled = true;
            }
        });

        if (
            this.inputTarget.getAttribute('min') &&
            this.inputTarget.value === this.inputTarget.getAttribute('min') &&
            this.hasSubstractButtonTarget
        ) {
            this.substractButtonTarget.disabled = true;
        }

        if (
            this.inputTarget.getAttribute('max') &&
            this.inputTarget.value === this.inputTarget.getAttribute('max') &&
            this.hasAddButtonTarget
        ) {
            this.addButtonTarget.disabled = true;
        }
    }

    add() {
        let max =
            this.inputTarget.getAttribute('max') ?? +this.inputTarget.value + 1;
        this.inputTarget.value =
            +this.inputTarget.value < max ? +this.inputTarget.value + 1 : max;

        if (
            this.hasAddButtonTarget &&
            null !== this.inputTarget.getAttribute('max') &&
            this.inputTarget.value === max
        ) {
            this.addButtonTarget.disabled = true;
        }

        if (this.hasSubstractButtonTarget && this.inputTarget.value > 0) {
            this.substractButtonTarget.disabled = false;
        }

        this.inputTarget.dispatchEvent(new Event('input'));
    }

    substract() {
        let min = this.inputTarget.getAttribute('min') ?? 0;
        let max = this.inputTarget.getAttribute('max') ?? null;

        this.inputTarget.value =
            +this.inputTarget.value > min ? +this.inputTarget.value - 1 : min;

        if (
            this.hasAddButtonTarget &&
            (null == max || this.inputTarget.value < max)
        ) {
            this.addButtonTarget.disabled = false;
        }

        if (this.hasSubstractButtonTarget && this.inputTarget.value === min) {
            this.substractButtonTarget.disabled = true;
        }

        this.inputTarget.dispatchEvent(new Event('input'));
    }
}
