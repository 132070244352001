import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['button', 'input'];

    static values = {
        total: {
            type: Number,
            default: 0,
        },
        from: String,
    };

    connect() {
        // sometimes component has no button (e.g price_line_selector when priceLines = 1)
        if (this.hasButtonTarget) {
            this.fromValue = this.buttonTarget.innerHTML;
        }
        this.totalValue = this.inputTargets.reduce(
            (total, { valueAsNumber }) => total + valueAsNumber,
            0,
        );

        if (this.totalValue && this.hasButtonTarget) {
            this.buttonTarget.innerHTML = 'Modifier la sélection';
            this.buttonTarget.classList.add('popover__button--active');
        }
    }

    update(event) {
        const value = parseInt(event.target.value);

        if (!isNaN(value)) {
            const previousValue =
                parseInt(event.target.dataset.previousValue) || 0;
            this.totalValue += value - previousValue;
            event.target.dataset.previousValue = value;
        }

        if (this.totalValue === 0 && this.hasButtonTarget) {
            this.buttonTarget.innerHTML = this.fromValue;
            this.buttonTarget.classList.remove('popover__button--active');
            return;
        }
        if (this.hasButtonTarget) {
            this.buttonTarget.innerHTML = 'Modifier la sélection';
            this.buttonTarget.classList.add('popover__button--active');
        }
    }
}
