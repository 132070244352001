import { Controller } from '@hotwired/stimulus';
import Pikaday from 'pikaday';
import pikdayI18n from '../js/legacy/front/js/src/ui/pikaday-i18n';

export default class extends Controller {
    connect() {
        new Pikaday({
            ...window.app.config.pikaday,
            field: this.element,
            i18n: pikdayI18n[window.app.config.lang],
            firstDay: window.app.config.pikaday.firstDay,
            format: window.app.config.pikaday.format,
            minDate: new Date(),
        });
    }
}
