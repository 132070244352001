import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['element'];

    connect() {
        const inputs = this.element.getElementsByTagName('input');
        const shouldShow = this.element.dataset?.negateToggle ? '0' : '1';

        if (inputs) {
            for (let input of inputs) {
                // yes
                if (input.type === 'radio' && input.value === shouldShow) {
                    input.onclick = this.show.bind(this);
                }
                // no
                if (input.type === 'radio' && input.value !== shouldShow) {
                    input.onclick = this.hide.bind(this);
                }
            }
        }
    }

    // show the element if it is hidden
    show() {
        this.elementTarget.classList.remove('hidden');
    }

    hide() {
        this.elementTarget.classList.add('hidden');
    }
}
